import type { CartItem } from '#types/cart'

/**
 *
 * @param {CartItem} item - an item with optionItems property for giftBoxPrice extraction
 * @returns {number} giftBoxPrice from product item
 */
export const getItemGiftBoxPrice = ({ optionItems = [] }: CartItem): number => {
  const option = optionItems.find(
    (el) => el.optionId === 'giftBox' && el.optionValueId === 'withGiftBox'
  )
  return option?.price || 0
}
