<template>
  <section v-if="savedItems?.count" aria-labelledby="cart-saved-for-later-heading">
    <vf-page-loader v-if="pending" />
    <header class="mb-4 flex b-y b-grey-50 py-2 text-center between lg:mb-6 md:py-6">
      <h2 id="cart-saved-for-later-heading" class="!subtitle-3 title-3">
        {{ $t.savedForLater }}
      </h2>
      <div class="fw-bold md:pr-4 " data-test-id="cart-total-qty">
        {{ savedItems.total }}
        {{ $pluralize($t.items, savedItems.total) }}
      </div>
    </header>
    <!-- TS-FIX Type 'CartItem' is not assignable to type 'BaseCartItem' -->
    <cart-item
      v-for="(item, i) in savedItems.items"
      :key="item.id"
      class="py-4 lg:py-6"
      :class="{
        'pt-0': i === 0,
        'b-b b-grey-50 ': i !== savedItemsLength - 1,
      }"
      data-test-id="saved-cart-product"
      :item="item as any"
      :pending-item-id="pendingItemId"
      variant="saved-for-later"
      @edit="$emit('edit', { item, savedForLater: true })"
      @set-qty="$emit('set-qty', { item, qty: $event })"
    >
      <base-popover
        v-slot="{ toggle, close, opened }"
        class="flex center lg:hidden"
        :offset="-1"
        placement="bottom-end"
      >
        <base-button
          aria-controls="cart-popover"
          :aria-expanded="opened"
          :aria-label="$t.showCartItemActionMenu"
          class="relative lg:hidden"
          data-test-id="action-menu-toggle"
          style="right: -0.5rem"
          @click="$viewport.md ? toggle($event) : openActionMenu(item)"
          @keydown.esc="close"
        >
          <vf-icon name="dots" size="lg" />
        </base-button>
        <base-popover-content
          id="cart-popover"
          class="z-1 w-54 rounded-sm rounded-tl-none bg-white shadow-lg ring ring-grey-70 ring-inset"
          @keydown.esc="close"
        >
          <cart-item-action-menu
            data-test-id="save-for-later-action-menu"
            hide-save-for-later
            @edit="$emit('edit', { item, savedForLater: true })"
            @remove="removeSavedItem(item)"
          />
        </base-popover-content>
      </base-popover>
      <template #end>
        <div class="mt-4 md:flex md:justify-end md:gap-4" style="padding-left: 7.375rem">
          <base-button
            class="flex items-center gap-2 text-sm underlined"
            data-test-id="move-to-cart"
            @click="$emit('move-to-cart', item)"
          >
            <vf-icon class="<md:hidden" :name="moveToCartIcon" size="md" />
            {{ $t.moveToCart }}
          </base-button>
          <base-button
            class="flex items-center gap-2 text-sm underlined <lg:hidden"
            data-test-id="remove"
            @click="removeSavedItem(item)"
          >
            <vf-icon name="close" size="md" />
            {{ $t.removeItem }}
          </base-button>
        </div>
      </template>
    </cart-item>
    <div
      v-if="savedItemsLength < savedItems.total"
      class="mb-8 mt-6 flex justify-center underlined lg:mb-12 lg:mt-14 md:mb-10 md:mt-13"
    >
      <base-button @click="$emit('load-all')">
        {{ $t.viewAll }}
      </base-button>
    </div>

    <dialog-cart-item-action-menu class-content="px-0 pb-0" hide-backdrop position="bottom" size="lg" />
  </section>
</template>

<script lang="ts" setup>
import type { CartItem as CartItemType, SavedItems } from '#types/cart'

const props = defineProps<{
  pending: boolean
  pendingItemId: string
  savedItems?: SavedItems
}>()

const emit = defineEmits<{
  'edit': [payload: { item: CartItemType, savedForLater: boolean }]
  'load-all': []
  'move-to-cart': [payload: CartItemType]
  'remove': [payload: CartItemType]
  'set-qty': [payload: { item: CartItemType, qty: number }]
}>()

const { dialogOptions, moveToCartIcon } = useAppConfig().pages.cart.savedForLater

const DialogCartItemActionMenu = createDialog('cart-item-action-menu', dialogOptions)

const removeSavedItem = async (item: CartItemType) => {
  emit('remove', item)
}

const openActionMenu = async (item: CartItemType) => {
  const action = await DialogCartItemActionMenu.open({ hideSaveForLater: true })

  switch (action) {
    case 'remove':
      removeSavedItem(item)
      break
    case 'edit':
      emit('edit', { item, savedForLater: true })
      break
  }
}

const savedItemsLength = computed(() => props.savedItems?.items?.length || 0)
</script>
