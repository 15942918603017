<template>
  <section>
    <header v-if="showHeader" class="mb-4 b-y b-grey-50 py-2 text-center lg:mb-6 md:py-6">
      <div class="flex between">
        <h2 class="!subtitle-3 title-3">
          {{ $t.outOfStock }}
        </h2>
        <div
          class="fw-bold md:pr-4 "
          data-test-id="cart-oos-total-qty"
        >
          {{ items.length }}
          {{ $pluralize($t.items, items.length) }}
        </div>
      </div>
    </header>
    <cart-item
      v-for="(item, i) in items"
      :key="item.id"
      class="py-4 lg:py-6"
      :class="{
        'pt-0': i === 0,
        'b-b b-grey-70': i < items.length - 1,
      }"
      data-test-id="cart-product"
      :item="mapCartItem(item)"
      :order-promo-summary="cart.orderPromoSummary"
      :pending-item-id="item.pendingItemId"
      variant="out-of-stock"
      @edit="editItem(item)"
      @remove="removeItem(item.productId)"
    >
      <base-popover
        v-slot="{ toggle, close, opened }"
        class="flex center lg:hidden"
        :offset="-1"
        placement="bottom-end"
      >
        <base-button
          aria-controls="cart-popover"
          :aria-expanded="opened"
          :aria-label="$t.showCartItemActionMenu"
          class="relative lg:hidden"
          data-test-id="action-menu-toggle"
          style="right: -0.5rem"
          @click="$viewport.md ? toggle($event) : openActionMenu(item)"
          @keydown.esc="close"
        >
          <vf-icon name="dots" size="lg" />
        </base-button>
        <base-popover-content
          id="cart-popover"
          class="z-1 w-54 rounded-sm rounded-tl-none bg-white shadow-lg ring ring-grey-70 ring-inset"
          @keydown.esc="close"
        >
          <cart-item-action-menu
            data-test-id="action-menu"
            @edit="editItem(item)"
            @remove="removeItem(item.productId)"
            @save-for-later="saveForLater(item)"
          />
        </base-popover-content>
      </base-popover>
      <template #end>
        <div class="mt-4 lg:grid lg:cols-[1fr_auto] lg:gap-4 ">
          <p class="eyebrow ">
            {{ $t.thisItemHasBeenRemoved }}
          </p>
          <div v-if="showRemoveItem || showSaveForLater" class="flex items-end justify-end gap-4 <lg:hidden">
            <base-button
              v-if="showSaveForLater && $feature.allowSaveForLater && item.isPartialOos"
              class="flex items-center gap-2 text-sm underlined"
              data-test-id="save-for-later"
              @click="saveForLater(item)"
            >
              <vf-icon dir="down" name="arrow" size="md" />
              {{ $t.saveForLater }}
            </base-button>
            <base-button
              v-if="showRemoveItem"
              class="flex items-center gap-2 text-sm underlined"
              data-test-id="remove"
              @click="removeItem(item.productId)"
            >
              <vf-icon  name="close" size="md" />
              {{ $t.removeItem }}
            </base-button>
          </div>
        </div>
      </template>
    </cart-item>
    <vf-button
      v-if="showRemoveAll"
      class="mb-10 mt-5 w-full"
      data-test-id="remove-out-of-stock-items"
      variant="secondary"
      @click="removeAll"
    >
      {{ $t.removeAll }}
    </vf-button>

    <dialog-cart-item-action-menu class-content="px-0 pb-0" hide-backdrop position="bottom" size="lg" />
  </section>
</template>

<script lang="ts" setup>
import type { BaseCartItem, FlashErrorDetails } from '#types/cart'

defineProps<{
  items: FlashErrorDetails[]
}>()

const emit = defineEmits<{
  'edit': [payload: boolean]
  'save-for-later': [payload: {
    id?: string
    recipeId?: string
    productId?: string
    qty?: number
    pdpUrl?: string
    productImageURL?: string
  }]
}>()

const {
  dialogOptions,
  showHeader,
  showRemoveAll,
  showRemoveItem,
  showSaveForLater
} = useAppConfig().pages.cart.outOfStock

const { $t, $viewport } = useNuxtApp()

const DialogCartItemActionMenu = createDialog('cart-item-action-menu', dialogOptions)

const cart = useCartStore()

// Mapping FlashErrorDetails into BaseCartItem so we can reuse the same cart item component
const mapCartItem = (item: FlashErrorDetails): BaseCartItem => {
  const variants = Object.entries(item.variationAttributes ?? {}).map(([key, value]) => ({
    id: key,
    code: key,
    label: $t[key],
    value
  }))

  const {
    masterProductId = '',
    variationGroupId = '',
    productName = '',
    missingQty = 0,
    maxAllowedQty = 0,
    salesPrice = 0,
    productImageURL = '',
    productURL = ''
  } = item

  const price = {
    lowOriginal: salesPrice,
    highOriginal: salesPrice,
    lowCurrent: salesPrice,
    highCurrent: salesPrice,
    rowTotal: salesPrice,
    originalRowTotal: salesPrice,
    priceAfterOrderDiscount: salesPrice,
    priceAfterItemDiscount: salesPrice,
    proratedPrice: salesPrice,
    amount: salesPrice,
    current: salesPrice,
    original: salesPrice,
    currency: cart.currency
  }

  return {
    id: masterProductId,
    itemId: variationGroupId,
    name: productName,
    qty: missingQty,
    maxAllowedQty,
    maxQty: missingQty,
    price,
    productImageURL,
    pdpUrl: productURL,
    variants
  }
}

const removeAll = () => {
  cart.outOfStockItems = []
}

const removeItem = (id?: string) => {
  if (!id)
    removeAll()
  else
    cart.outOfStockItems = cart.outOfStockItems.filter((item) => item.productId !== id)
}

const removeSavedItem = async (item: FlashErrorDetails) => {
  removeItem(item.productId)
  cart.notification.message = ''
}

const editItem = async (item) => {
  emit('edit', { ...item, id: item.masterProductId, masterId: item.variationGroupId, oos: true })
}

const saveForLater = async (item: FlashErrorDetails) => {
  // since we need cart item id reference to save for later, we can do this only if the product is partially out of stock
  if (item.isPartialOos) {
    emit('save-for-later', {
      id: item.id,
      recipeId: '',
      productId: item.productId,
      qty: item.missingQty,
      pdpUrl: item.productURL,
      productImageURL: item.productImageURL
    })
  }
}

const openActionMenu = async (item) => {
  const action = await DialogCartItemActionMenu.open()

  const actions = {
    'remove': removeSavedItem,
    'edit': editItem,
    'save-for-later': saveForLater
  }

  actions[action]?.(item)
}

onUnmounted(() => cart.notification.message = '')
</script>
