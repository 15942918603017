<template>
  <div class="pr-2 lg:pr-4 ">
    <define-cart-item-price>
      <div class="<md:mt-2 md:text-right ">
        <span v-if="item.bonus" class="text-sm uppercase fw-bold">{{ $t.free }}</span>
        <product-pricing
          v-else-if="price
            && (variant !== 'out-of-stock' || outOfStock.showPrice)"
          class="md:justify-end"
          class-current="fw-bold"
          compact-omnibus
          :currency="price.currency"
          data-test-id="price"
          :price
          :show-discount-percentage="$feature.showOmnibusPricing && !!price.historicBestPrice"
          :show-original-price-for-omnibus="$feature.showOmnibusBadgeOnCartAndCheckout"
        />
        <p v-else-if="variant === 'out-of-stock' && !outOfStock.showPrice" class="uppercase">
          {{ $t.outOfStock }}
        </p>
      </div>
    </define-cart-item-price>
    <define-cart-item-promotions>
      <div
        v-if="!item.bonus && (item.promoExclusionsMessage || showItemLevelPromos || showOrderLevelPromos)"
        class="mt-4 eyebrow c-grey-20 !c-grey-10"
      >
        <div class="md:max-w-64 space-y-2">
          <p>{{ item.promoExclusionsMessage }}</p>
          <!-- Items may have multiple coupons applied, but for GWP item we only show as Free -->
          <template v-if="showItemLevelPromos">
            <template
              v-for="{
                calloutMsgDetail,
                promotionId,
                calloutMsg,
                itemText,
              } in item.productPromotions"
              :key="promotionId"
            >
              <cart-promo-toggletip
                v-if="calloutMsgDetail"
                class="eyebrow"
                :details="calloutMsgDetail"
                :text="calloutMsg || itemText"
              />
              <p v-else class="text-sm">
                {{ calloutMsg || itemText }}
              </p>
            </template>
          </template>
          <template v-if="showOrderLevelPromos && !item.recipeId">
            <template
              v-for="{
                calloutMessage,
                promoDetails,
                promotionId,
                promotionName,
              } in orderPromoSummary"
              :key="promotionId"
            >
              <cart-promo-toggletip
                v-if="promoDetails"
                class="eyebrow"
                :details="promoDetails"
                :text="calloutMessage || promotionName"
              />
              <p v-else class="text-sm">
                {{ calloutMessage || promotionName }}
              </p>
            </template>
          </template>
        </div>
      </div>
      <div
        v-if="athleteExclusionMessage || item.notEligibleProductInfoMsg"
        :class="{
          'mt-2 max-w-40 flex gap-1 text-right text-xs': athleteExclusionMessage,
          'mt-4 flex items-center eyebrow c-grey-20 !c-grey-10':
            !athleteExclusionMessage && item.notEligibleProductInfoMsg,
        }"
      >
        <p v-if="athleteExclusionMessage">
          {{ athleteExclusionMessage }}
        </p>
        <p v-else-if="item.notEligibleProductInfoMsg">
          {{ item.notEligibleProductInfoMsg }}
        </p>
        <vf-icon name="info" size="sm" />
      </div>
    </define-cart-item-promotions>

    <vf-notification v-for="({ message }, index) in notifications" :key="index" class="mb-4 lg:mb-6" :dismissible="false" type="info">
      {{ message }}
    </vf-notification>
    <div class="flex between">
      <p class="line-clamp-3 fw-bold ">
        <template v-if="disablePdpLinks">
          {{ item.name }}
        </template>
        <vf-link v-else data-test-id="name" :to="{ ...pdpRoute, state: { category: 'Direct from Cart' } }" variant="quiet">
          {{ item.name }}
        </vf-link>
      </p>
      <slot />
    </div>
    <div class="mt-2 flex gap-6 md:mt-4 ">
      <component
        :is="disablePdpLinks ? 'div' : BaseLink"
        class="shrink-0 self-start"
        data-test-id="thumbnail"
        :to="disablePdpLinks ? undefined : { ...pdpRoute, state: { category: 'Direct from Cart' } }"
      >
        <base-picture
          :alt="item.name"
          :class="{ '': variant === 'out-of-stock' }"
          :height="cartItem.thumbnails.height"
          :src="getResponsiveImageTransformations(resolveProductImgUrl(item.productImageURL), cartItem.thumbnails)"
          :width="cartItem.thumbnails.width"
        />
      </component>
      <div class="w-full flex between">
        <div>
          <div class="text-sm md:flex md:between">
            <gift-card-summary v-if="item.isDigitalProduct && item.giftOption" :gift-option="item.giftOption" />
            <div v-else :class="brandClasses.itemVariants" data-test-id="variants">
              <p v-for="itemVariant in sortVariants(item.variants, variantOrder)" :key="itemVariant.value" class="line-clamp-2">
                <span v-if="cartItem.variants?.showLabel" class="fw-bold">{{ itemVariant.label }}:</span> {{ itemVariant.value }}
              </p>
            </div>
            <cart-item-price v-if="!$viewport.md" />
          </div>
          <div v-if="!item.isDigitalProduct" class="mt-4 space-y-1 ">
            <!-- maxAllowedQty for Cart items, maxQty for SavedForLater items because its a soft inventory check -->
            <vf-stepper
              v-if="!['saved-for-later', 'out-of-stock'].includes(variant)"
              data-test-id="qty-stepper"
              :disabled="pendingItemId === item.id || item.bonus"
              :max="(item.maxAllowedQty && item.maxAllowedQty < item.maxQty) ? item.maxAllowedQty : item.maxQty"
              :min="cartItem.allowRemovalByDecreasingQty ? 0 : 1"
              :model-value="item.qty"
              @update:model-value="$emit('set-qty', +$event)"
            >
              <template v-if="cartItem.allowRemovalByDecreasingQty && item.qty === 1" #decrease-icon="{ size }">
                <vf-icon name="trash" :size />
              </template>
            </vf-stepper>
            <template v-if="variant !== 'out-of-stock'">
              <vf-form-hint v-if="item.maxQty && item.maxQty <= lowQtyThreshold">
                {{ replaceAll($t.qtyLimitLow, { limit: item.maxQty }) }}
              </vf-form-hint>
              <vf-form-hint v-else-if="item.maxAllowedQty && item.maxAllowedQty === item.qty">
                {{ replaceAll($t.qtyLimit, { limit: item.maxAllowedQty }) }}
              </vf-form-hint>
            </template>
          </div>
          <base-button
            v-if="!item.bonus"
            :class="brandClasses.editItemCta"
            data-test-id="edit-product"
            :disabled="pendingItemId === item.id"
            @click="$emit('edit')"
          >
            <vf-icon  name="edit" size="md" />
            {{ $t.editItem }}
          </base-button>
          <cart-item-promotions v-if="!$viewport.md" />
        </div>
        <div v-if="$viewport.md" >
          <cart-item-price />
          <cart-item-promotions />
        </div>
      </div>
    </div>
    <slot name="end" />
  </div>
</template>

<script lang="ts" setup>
import defu from 'defu'
import { createReusableTemplate } from '@vueuse/core'
import { BaseLink } from '#components'
import type { PromoSummary } from '#root/api/clients/cart/data-contracts'
import type { BaseCartItem } from '#types/cart'

interface Props {
  item: BaseCartItem
  pendingItemId?: string
  orderPromoSummary?: PromoSummary[]
  variant?: 'cart' | 'out-of-stock' | 'saved-for-later'
  notifications?: { message: string }[]
}

const props = withDefaults(defineProps<Props>(), {
  pendingItemId: '',
  variant: 'cart',
  notifications: () => []
})

defineEmits<{
  'edit': []
  'set-qty': [qty: number]
}>()

const {
  disableBonusPdpLinks,
  brandClasses,
  variantOrder,
  outOfStock,
  showItemLevelPromocode,
  showOrderLevelPromocodeOnItem,
  cartItem
} = useAppConfig().pages.cart

const { $t, $feature } = useNuxtApp()
const user = useUserStore()

const showPriceOverrideForm = ref(false)

const showItemLevelPromos = computed(() => showItemLevelPromocode && props.item.productPromotions?.length)
const showOrderLevelPromos = computed(() =>
  showOrderLevelPromocodeOnItem
  && props.orderPromoSummary?.length
  && props.item.price?.priceAfterOrderDiscount !== props.item.price?.original
)

const disablePdpLinks = computed(() => {
  return !$feature.allowItemLinksToPdpOnCart || (disableBonusPdpLinks && props.item.bonus)
})

const storeInfo = computed(() => props.item.selectedShippingOption?.storeInfo)
const pdpRoute = computed(() => defu(getRouteFromUrl(props.item.pdpUrl), {
  query: {
    ...(storeInfo.value?.id && { storeFilter: storeInfo.value.id })
  }
}))

const lowQtyThreshold = 10 // used to show messaging when low quantities are identified

const [DefineCartItemPrice, CartItemPrice] = createReusableTemplate()
const [DefineCartItemPromotions, CartItemPromotions] = createReusableTemplate()

const price = computed(() => {
  const price = props.item.price
  if (!price) return
  const isCartVariant = props.variant === 'cart'
  const originalPrice = isCartVariant ? price.originalRowTotal || price.rowTotal : price.original
  const currentPrice = isCartVariant ? price.priceAfterOrderDiscount : price.current

  return {
    currency: price.currency,
    lowOriginal: originalPrice,
    highOriginal: originalPrice,
    lowCurrent: currentPrice,
    highCurrent: currentPrice,
    historicBestPrice: price.historicBestPrice,
    percentagePriceDiscount: price.percentagePriceDiscount,
  }
})

const athleteExclusionMessage = computed(() => {
  const { price, productPromotions, bonus } = props.item
  if (!user.isAthleteProfile || !price || bonus)
    return
  if (price!.original > price!.current)
    return $t.excludedFromAthleteDueSale
  if (!productPromotions?.some(({ promotionId }) => promotionId === '50%_ATHLETES'))
    return $t.excludedFromAthleteMessage
})

defineExpose({
  showPriceOverrideForm
})
</script>
