<template>
  <div class="b-b b-grey-50 py-3">
    <div class="flex children:skeleton items-center between md:(w-3/5 pr-8)">
      <div class="h-8 w-32" />
      <div class="h-8 w-32" />
    </div>
  </div>
  <div class="md:flex">
    <div class="py-6 lg:w-2/3 md:(w-7/12 pr-8)">
      <cart-item-skeleton />
    </div>
    <div class="py-6 lg:w-1/3 md:w-5/12">
      <div class="h-11 w-full skeleton" />
      <div class="mt-4 h-37 w-full skeleton" />
      <div class="my-6 h-12 w-full skeleton" />
      <div class="flex p-4 pb-2 <md:hidden">
        <div class="mr-10 h-4 w-full skeleton" />
        <div class="h-4 w-5 skeleton" />
      </div>
      <div class="flex p-4 pb-2 <md:hidden">
        <div class="mr-10 h-4 w-full skeleton" />
        <div class="h-4 w-5 skeleton" />
      </div>
      <div class="flex p-4 <md:hidden">
        <div class="mr-10 h-4 w-full skeleton" />
        <div class="h-4 w-5 skeleton" />
      </div>
    </div>
  </div>
  <div class="mt-10 h-8 w-48 skeleton" />
  <div class="grid cols-2 mb-10 mt-4 children:skeleton gap-4 lg:cols-6 md:cols-4">
    <div class="aspect-square" />
    <div class="aspect-square" />
    <div class="aspect-square <md:hidden" />
    <div class="aspect-square <md:hidden" />
    <div class="aspect-square <lg:hidden" />
    <div class="aspect-square <lg:hidden" />
  </div>
</template>
