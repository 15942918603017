<template>
  <main>
    <vf-page-loader v-if="pendingItemId || cart.pending" />
    <div class="pt-6 container space-y-4 lg:px-8 ">
      <vf-notification
        v-if="spendBannerVisible"
        :dismissible="false"
        type="info"
      >
        {{ user.spendBannerMessage }}
      </vf-notification>
      <vf-notification
        v-if="cart.notification.message && cart.notification.level === 'top'"
        class="col-span-2"
        :type="cart.notification.type"
        @close="cart.notification.message = ''"
      >
        {{ cart.notification.message }}
      </vf-notification>
      <vf-notification
        v-if="isCartMerged"
        type="info"
        @close="cart.closeCartMergedNotification"
      >
        {{ $t.cartItemsMerged }}
      </vf-notification>
      <vf-notification
        v-for="{ id, message } in promotions"
        :key="id"
        type="info"
        @close="approachingPromotions.dismiss(id)"
      >
        <base-interpolator :content="message">
          <template #sign-in="{ args: [label] }">
            <base-button class="underlined" @click="DialogSignIn.open({ formLocation: 'modal:single:cart:none' })">
              {{ label }}
            </base-button>
          </template>
          <template #join-now="{ args: [label] }">
            <base-button class="underlined" @click="DialogSignUp.open({ formLocation: 'modal:single:cart:none' })">
              {{ label }}
            </base-button>
          </template>
        </base-interpolator>
      </vf-notification>
    </div>

    <div class="my-4 container lg:px-8">
      <cart-skeleton v-if="showSkeleton" />
      <template v-else>
        <cms-section
          v-if="eSpots?.['above-cart-header']"
          class="mb-1"
          :section="eSpots?.['above-cart-header']"
        />
        <header class="b-b py-3" :class="brandClasses.header">
          <div class="flex items-center between" :class="brandClasses.headerContent">
            <h1 :class="brandClasses.headerTitle">
              {{ $t.cart }}
            </h1>
            <span
              :class="brandClasses.headerTotalItems"
              data-test-id="cart-total-qty"
            >
              {{ totalItems }}
              {{ $pluralize($t.items, totalItems) }}
              <span v-if="showTotalInHeader && totalItems">
                <span class="mx-2" :class="brandClasses.price">|</span>
                {{ useFormattedPrice(totals.total, currency) }}
              </span>
            </span>
          </div>
        </header>
        <div class="md:flex">
          <!-- Items In Cart Section -->
          <section class="lg:w-2/3 md:w-7/12 md:pr-8">
            <vf-notification
              v-if="cart.notification.message && cart.notification.level === 'item'"
              class="col-span-2 mb-4 "
              :type="cart.notification.type"
              @close="cart.notification.message = ''"
            >
              {{ cart.notification.message }}
            </vf-notification>
            <div
              v-if="totalItems"
              class="divide-y divide-grey-50"
              :class="{ [brandClasses.items]: (savedItems?.count || outOfStockItems?.length) }"
            >
              <section v-for="(group, key) in cart.itemsPerShippingMethod" :key>
                <header
                  v-if="Object.keys(cart.itemsPerShippingMethod).length > 1"
                  class="flex b-b py-2 between md:py-6"
                  :class="[
                    brandClasses.itemsPerShipping,
                    {
                      'md:pt-2 lg:pt-0': Object.keys(cart.itemsPerShippingMethod)[0] === key,
                                          },
                  ]"
                >
                  <h2 class="subtitle-3 ">
                    {{ key === 'home' ? $t.shipToHome : $t.storePickup }}
                  </h2>
                  <div v-if="group" class="fw-bold md:pr-4 ">
                    {{ totalGroupItems(group.items) }}
                    {{ $pluralize($t.items, totalGroupItems(group.items)) }}
                  </div>
                </header>
                <template v-if="group">
                  <template v-for="(subgroupItems, subgroupKey) in groupByCustoms(group.items)">
                    <header
                      v-if="subgroupItems.length && subgroupKey === 'customs'"
                      :key="subgroupKey"
                      class="flex b-b b-grey-50 py-2 between md:py-6 "
                    >
                      <h2 class="subtitle-3 ">
                        {{ $t.customs.title }}
                      </h2>
                    </header>
                    <cart-item
                      v-for="(item, i) in subgroupItems"
                      :key="`${subgroupKey}-${item.id}`"
                      :ref="(el) => (cartItemRef[i] = el)"
                      class="py-4 lg:py-6"
                      :class="{
                        'pt-4 md:pt-0': i === 0,
                        'b-b b-grey-50 ':
                          i !== group.items.length - 1,
                      }"
                      data-test-id="cart-product"
                      :item="item as any"
                      :notifications="customerNotifications[item.id] || []"
                      :order-promo-summary="cart.orderPromoSummary"
                      :pending-item-id="pendingItemId"
                      @edit="editItem(item)"
                      @set-qty="setItemQuantity({ item, qty: $event })"
                    >
                      <base-popover
                        v-slot="{ toggle, close, opened }"
                        class="flex center lg:hidden"
                        :offset="-1"
                        placement="bottom-end"
                      >
                        <base-button
                          v-if="!item.bonus"
                          aria-controls="cart-popover"
                          :aria-expanded="opened"
                          :aria-label="$t.showCartItemActionMenu"
                          class="relative lg:hidden"
                          data-test-id="action-menu-toggle"
                          :disabled="pendingItemId || cart.pending"
                          style="right: -0.5rem"
                          @click="$viewport.md ? toggle($event) : openActionMenu(item)"
                          @keydown.esc="close"
                        >
                          <vf-icon name="dots" size="lg" />
                        </base-button>
                        <base-popover-content
                          id="cart-popover"
                          class="z-1 w-54 rounded-sm rounded-tl-none bg-white shadow-lg ring ring-grey-70 ring-inset"
                          @keydown.esc="close"
                        >
                          <cart-item-action-menu
                            data-test-id="action-menu"
                            :hide-save-for-later="item.isDigitalProduct"
                            @edit="editItem(item)"
                            @remove="removeItem(item)"
                            @save-for-later="saveForLater(item)"
                          />
                        </base-popover-content>
                      </base-popover>
                      <template #end>
                        <div class="mt-4 lg:grid lg:cols-[1fr_auto] lg:gap-4 [1fr_auto]">
                          <div v-if="item.bonus" class="cols-span-2 flex gap-2">
                            <vf-icon name="info" size="lg" />
                            <span class="py-1">{{ $t.freeGiftIsNotEligibleForPickup }}</span>
                          </div>
                          <template v-else>
                            <div >
                              <template v-if="$feature.allowOrderLinePickup">
                                <div class="lg:hidden space-y-1">
                                  <div v-if="isPickupOrSts(item.selectedShippingOption.shippingMethod.code) && showSlaCopy" class="flex gap-1">
                                    {{ item.selectedShippingOption.shippingMethod.label }}
                                    <span
                                      v-if="item.selectedShippingOption.shippingMethod.deliveryTime
                                        && item.selectedShippingOption.storeInfo"
                                      class="fw-bold"
                                    >
                                      {{ item.selectedShippingOption.shippingMethod.deliveryTime }}
                                    </span>
                                    <vf-toggletip data-test-id="pickup-tooltip" placement="top-start">
                                      <template #trigger>
                                        <span class="sr-only">{{ item.selectedShippingOption.shippingMethod.label }}</span>
                                        <vf-icon name="info" size="md" />
                                      </template>
                                      {{ $t.selectStoreTooltip }}
                                    </vf-toggletip>
                                  </div>
                                  <p v-else class="fw-bold">
                                    {{ item.selectedShippingOption.shippingMethod.label }}
                                  </p>
                                  <p
                                    v-if="isPickupOrSts(item.selectedShippingOption.shippingMethod.code)"
                                    v-html="item.selectedShippingOption?.storeInfo?.name"
                                    class="text-sm"
                                  />
                                  <p v-else class="text-sm">
                                    {{ $t.shipsIn }} {{ item.selectedShippingOption.shippingMethod.deliveryTime }}
                                  </p>
                                  <base-button
                                    v-if="item.shippingOptions.length > 1"
                                    class="underlined "
                                    :class="links"
                                    data-test-id="shipping-method-toggle"
                                    @click="DialogCartChangeShippingMethod.open({ item })"
                                  >
                                    {{ $t.changeMethod }}
                                  </base-button>
                                </div>
                                <cart-shipment-selector
                                  class="<lg:hidden"
                                  :item
                                  :loading="cart.pending"
                                  @shipping-option-change="cart.updateItemShippingMethod(item, $event.storeId)"
                                />
                              </template>
                              <template v-if="!$feature.allowPickupOrder">
                                <p class="fw-bold">
                                  {{ item.selectedShippingOption.shippingMethod.label }}
                                </p>
                                <p class="mt-1 text-sm">
                                  {{ getDeliveryTimeLabel(item.selectedShippingOption.shippingMethod.deliveryTime) }}
                                </p>
                              </template>
                              <base-button
                                v-if="getGiftOptionAllowed(item) && !item.gift"
                                class="mt-4 flex items-center gap-1 text-sm underlined"
                                data-test-id="add-gift-option"
                                @click="DialogAddGiftOption.open({ item })"
                              >
                                <vf-icon name="gift" size="md" />
                                {{ $t.giftOption.addGiftOption }}
                              </base-button>
                              <div v-if="getGiftOptionAllowed(item) && item.gift" class="mt-4 bg-grey-90 p-4 text-sm" style="max-width: 28rem">
                                <p class="flex gap-1 text-base fw-medium">
                                  <vf-icon name="gift" size="md" /> {{ $t.giftOption.giftOptions }}
                                </p>
                                <p class="py-2">
                                  <span class="fw-medium">{{ $t.giftOption.to }}:</span>
                                  {{ item.giftOption.to }}<br>
                                  <span class="fw-medium">{{ $t.giftOption.from }}:</span>
                                  {{ item.giftOption.from }}<br>
                                  <span class="fw-medium">{{ $t.giftOption.message }}: </span>
                                  <span class="break-words">{{ item.giftOption.message }}</span><br>
                                  <span class="fw-medium">{{ $t.giftOption.giftBox }}:</span>
                                  {{
                                    item.isGiftBoxSelected
                                      ? replaceAll($t.giftOption.giftBoxYes, {
                                        price: useFormattedPrice(getItemGiftBoxPrice(item), useCurrencyCode()),
                                      })
                                      : $t.giftOption.giftBoxNo
                                  }}
                                </p>
                                <div class="flex gap-2">
                                  <base-button
                                    class="underlined"
                                    data-test-id="edit-gift-option"
                                    :disabled="cart.pending"
                                    @click="DialogAddGiftOption.open({ item })"
                                  >
                                    {{ $t.edit }}
                                  </base-button>
                                  <base-button
                                    class="underlined"
                                    data-test-id="remove-gift-option"
                                    :disabled="cart.pending"
                                    @click="cart.updateItemGiftOption(item, {
                                      ...(item.isGiftBoxSelected && {
                                        isGiftBoxSelected: false,
                                      }),
                                      giftOption: {
                                        to: '',
                                        from: '',
                                        message: '',
                                      },
                                      gift: false,
                                    })"
                                  >
                                    {{ $t.remove }}
                                  </base-button>
                                </div>
                              </div>
                            </div>
                            <div
                              class="flex items-end justify-end gap-4 <lg:hidden"
                              :class="{ 'relative -mt-6': !$feature.allowOrderLinePickup }"
                            >
                              <base-button
                                v-if="$feature.allowSaveForLater && !item.isDigitalProduct"
                                class="flex items-center gap-2 underlined"
                                :class="links"
                                data-test-id="save-for-later"
                                :disabled="pendingItemId === item.id"
                                @click="saveForLater(item)"
                              >
                                <vf-icon dir="down" name="arrow" size="md" />
                                {{ $t.saveForLater }}
                              </base-button>
                              <base-button
                                class="flex items-center gap-2 underlined "
                                :class="links"
                                data-test-id="remove"
                                :disabled="pendingItemId === item.id"
                                @click="removeItem(item)"
                              >
                                <vf-icon  name="close" size="md" />
                                {{ $t.removeItem }}
                              </base-button>
                            </div>
                          </template>
                          <base-button
                            v-if="employeeConnected
                              && (cartItemRef[i] && !cartItemRef[i].showPriceOverrideForm)
                              && !!item.price.current"
                            class="ml-a mt-4 flex items-center gap-1 text-sm underlined"
                            data-test-id="price-override-button"
                            @click="cartItemRef[i].showPriceOverrideForm = true"
                          >
                            <vf-icon name="edit" />{{ $t.priceOverride }}
                          </base-button>
                          <form-price-override
                            v-if="cartItemRef[i] && cartItemRef[i].showPriceOverrideForm"
                            class="mt-4"
                            :item
                            :override-complete="overrideComplete"
                            :reset-override-complete="resetOverrideComplete"
                            @override-price="overridePrice($event)"
                            @reset-price="resetPrice($event, i)"
                          />
                        </div>
                      </template>
                    </cart-item>
                  </template>
                </template>
              </section>
              <!-- espot: below-cart-items -->
              <cms-section
                v-if="eSpots?.['below-cart-items']"
                :class="totalItems > 1 ? '<lg:pt-4 <lg:mt-4 pt-4 mt-4' : 'pt-4 mt-4'"
                lazy-media
                :section="eSpots?.['below-cart-items']"
              />
            </div>
            <div aria-live="polite">
              <p v-if="!totalItems" v-style="brandStyles?.noItems" :class="brandClasses.noItems">
                {{ $t.noCartItems }}
              </p>
            </div>
            <cart-out-of-stock
              v-if="outOfStockItems.length"
              :items="outOfStockItems"
              @edit="editItem"
              @save-for-later="saveForLater"
            />
            <cart-saved-for-later
              v-if="$feature.allowSaveForLater && $viewport.md"
              :pending="showSkeleton"
              :pending-item-id="pendingItemId"
              :saved-items="savedItems"
              @edit="({ item, savedForLater }) => editItem(item, { savedForLater })"
              @load-all="fetchSavedCartItems(savedForLaterPageSize, savedItems.total)"
              @move-to-cart="moveSavedItemToCart"
              @remove="removeSavedItem"
              @set-qty="setSavedItemQuantity"
            />
          </section>
          <!-- Order Summary Section -->
          <cart-order-summary />
        </div>
        <!-- espot: cart-above-footer -->
        <cms-section
          v-if="eSpots?.['lazy-above-footer']"
          :class="totalItems > 1 ? '<lg:mt-8 mt-6' : 'mt-8'"
          lazy-media
          :section="eSpots?.['lazy-above-footer']"
        />
      </template>
    </div>

    <client-only>
      <dialog-quickshop
        class-content="<lg:pb-0"
        :size="$viewport.lg ? 'lg' : undefined"
      />
      <dialog-cart-item-action-menu class-content="px-0 pb-0" hide-backdrop position="bottom" size="lg" />
      <dialog-cart-change-shipping-method class-content="!px-0 !pb-0" position="bottom" size="lg" />
      <dialog-pickup-in-store
        :class-content="{ '!px-0 !pb-0': !$viewport.md }"
        :size="!$viewport.lg ? 'lg' : undefined"
      />
      <dialog-store-changed
        :class-content="{ '!px-0 !pb-0': !$viewport.md }"
        position="bottom"
        :size="!$viewport.md ? 'lg' : undefined"
      />
      <dialog-change-store-confirmation
        :class-content="{ '!px-0 !pb-0': !$viewport.md }"
        position="bottom"
        :size="!$viewport.md ? 'lg' : undefined"
      />
      <dialog-add-gift-option v-if="isGiftOptionAvailable" />
    </client-only>
    <teleport v-if="menuTeleportRef" to="[data-teleport='menu-buttons']">
      <vf-button
        class="relative grow ws-nowrap"
        :class="brandClasses.floatingCta?.(header.expanded)"
        class-content="overflow-hidden"
        :disabled="!header.expanded && !totalItems"
        :style="brandStyles?.floatingCta"
        @click="header.expanded ? header.expanded = false : router.push('/checkout')"
      >
        <span class="duration" :style="`width: ${header.expanded ? '1.5rem' : 'calc(100vw - 9rem)'}`">
          <span v-if="header.expanded">
            <span class="sr-only">{{ $t.mobileNavigationMinimize }}</span>
            <vf-icon name="close" size="md" />
          </span>
          <span v-else>
            {{ $t.checkout }}
          </span>
        </span>
      </vf-button>
    </teleport>
  </main>
</template>

<script lang="ts" setup>
import type { GetPaymentSessionData } from '#root/api/clients/cart/data-contracts'
import type { ProductVariants } from '#root/api/clients/product/data-contracts'
import { ApiErrorCode } from '#root/enums/api'
import type { CartItem as CartItemType } from '#types/cart'

const {
  cart: {
    brandClasses,
    brandStyles,
    cartItem: { allowRemovalUndo },
    quickshop,
    item: { links, actionMenu },
    savedForLater: { savedForLaterPageSize },
    showSlaCopy,
    showTotalInHeader
  }
} = useAppConfig().pages

const header = useHeaderStore()

const menuTeleportRef = useState<HTMLElement>('menuTeleportRef')
const auth = useAuthStore()
const { cart: cartApi } = useApi()
const cart = useCartStore()
const { getSections } = useCms()

const {
  DialogAddGiftOption,
  DialogCartChangeShippingMethod,
  DialogChangeStoreConfirmation,
  DialogPickupInStore,
  DialogSignIn,
  DialogSignUp,
  DialogStoreChanged
} = useDialogsStore()
const DialogCartItemActionMenu = createDialog('cart-item-action-menu', actionMenu.dialogOptions)
const DialogQuickshop = createDialog('quickshop', quickshop.dialogOptions)
const spendBannerVisible = useDiscount()
const {
  allowSaveForLater,
  enableApproachingDiscounts,
  showEstimatedDeliveryOnCart,
} = useFeatureFlags()
const { $deviceFingerPrint, $feature, $gtm, $t, $viewport, $sendExtraMonetateEvents } = useNuxtApp()
const user = useUserStore()
const loyalty = useLoyaltyStore()
const router = useRouter()
const toast = useToaster()
const recaptcha = useRecaptcha()
const { employeeConnected } = useBuyInStoreStore()
const { selectedStore } = useBopisStore(useCountryCode())
const approachingPromotions = useApproachingPromotionsStore()

const paymentProvider = ref<GetPaymentSessionData['provider']>()
const isAdyenEnabled = ref(false)
const meta = ref<GetPaymentSessionData['meta']>()
const locale = ref<string>()
const estimatedDelivery = ref({ hasDeliveryDate: false, label: '' })
const cartItemRef = ref({})
const overrideComplete = ref()
const resetOverrideComplete = ref()

const {
  add,
  fetchSavedCartItems,
  get,
  moveSavedItemBackToCart,
  remove,
  resetCachedCustomerNotifications,
  setItemQty
} = cart
const {
  basketId,
  cachedCustomerNotifications,
  currency,
  isCartMerged,
  outOfStockItems,
  savedCartPending,
  savedItems,
  shippingMethods,
  totalItems,
  totals
} = storeToRefs(cart)

const eSpots = (await getSections.cart()).data.value?.content.sectionsMap
get({ selectedStoreId: selectedStore?.enterprise_store_identifier, isPatchRequired: true })
if (allowSaveForLater && !employeeConnected) fetchSavedCartItems()

// The postman value, currently API is not using it, but it's required
const available = 'available33333333'

const customerNotifications = ref({} as typeof cachedCustomerNotifications)
const pendingItemId = ref('')
const showSkeleton = ref(allowSaveForLater && !employeeConnected)

const promotions = computed(() => {
  if (!enableApproachingDiscounts) return []
  return approachingPromotions.get('cart')
})

const groupByCustoms = (items: CartItemType[]) => ({
  standard: items.filter((item) => !item.isCustoms),
  customs: items.filter((item) => item.isCustoms)
})

const totalGroupItems = (items: CartItemType[]) =>
  items.reduce((agg, curr) => agg + curr.qty, 0)

const openQuickshop = (item, options = { savedForLater: false }) => {
  // If the item is out of stock, we provide no context so that a new item can be
  // added to the cart rather than trying to replace a non-existing item
  let context

  const gtmProduct = getGTMProduct(item.productId)

  if (!item.oos)
    context = options.savedForLater ? 'update-saved-item' : 'update-cart-item'

  DialogQuickshop.open({
    context,
    initialAttributes: item.variants
      ? item.variants.reduce((acc, variant) => ({ ...acc, [variant.code]: variant.id }), {})
      : {},
    product: {
      id: item.masterId,
      itemId: options.savedForLater ? item.itemId : item.id,
      name: item.name,
      currency: toValue(currency),
      price: {
        lowOriginal: item.oos ? item.salesPrice : item.price.original,
        highOriginal: item.oos ? item.basePrice : item.price.original,
        lowCurrent: item.oos ? item.salesPrice : item.price.current,
        highCurrent: item.oos ? item.basePrice : item.price.current
      },
      gallery: [{
        alt: item.name,
        src: item.productImageURL,
        type: 'image'
      }],
      attributes: [],
      variants: {} as ProductVariants,
      url: ''
    },
    gtmConfig: {
      ...(gtmProduct?.category && { category: gtmProduct.category }),
    },
    ...(item.isDigitalProduct ? { giftOption: item.giftOption } : {}),
    storeId: item.selectedShippingOption?.storeInfo?.id
  })
}

const editItem = (item, options = { savedForLater: false }) => {
  if (item.isCustoms)
    navigateTo(item.pdpUrl, { external: true })

  else
    openQuickshop(item, options)
}

const moveSavedItemToCart = async (item: CartItemType) => {
  try {
    await moveSavedItemBackToCart(item, savedItems.value.saveForLaterId)
    fetchSavedCartItems()
    toast.add({
      props: {
        title: $t.success,
        message: replaceAll($t.cartItemMovedToCart, { name: item.name }),
        type: 'success'
      }
    })
  }
  catch (err) {
    assertApiError(err)
    switch (err.errorId) {
      case ApiErrorCode.GWP_PRODUCT:
        cart.setNotification('error', $t.itemCannotAddedToCart, 'top')
        break
      case ApiErrorCode.ADDED_MAX_QUANTITY:
        toast.add({
          props: {
            message: cart.getMaxAllowedQtyMessage(err),
            type: 'error'
          }
        })
        break
      case ApiErrorCode.ITEM_OOS:
      case ApiErrorCode.ITEM_NOT_AVAILABLE:
        cart.setNotification('error', $t.thisItemCouldNotBeMovedToCart, 'top')
        break
    }
  }
}

const removeItem = async (item: CartItemType) => {
  const { id, maxQty, name, productId, productImageURL, qty, recipeId, upc } = item

  try {
    pendingItemId.value = id
    await remove(id, upc)
    toast.add({
      props: {
        title: $t.success,
        message: replaceAll($t.cartItemRemoved, { name }),
        type: 'success',
        ...(allowRemovalUndo
          ? { ctas: [{ label: $t.undo, isTextButton: true, click() {
              add({
                product: productId,
                upc,
                maxQty,
                storeId: item.selectedShippingOption.storeInfo?.id,
                ...(recipeId && { recipeId }),
                productImageURL,
                qty
              })
            }, }], }
          : {})
      }
    })
  }
  finally {
    pendingItemId.value = ''
  }
}

const removeSavedItem = async (item: CartItemType) => {
  try {
    pendingItemId.value = item.id

    await cartApi.$removeSavedForLater(
      item.itemId || '',
      savedItems.value.saveForLaterId,
      { body: 'null' }
    )

    fetchSavedCartItems()

    toast.add({
      props: {
        title: $t.success,
        message: replaceAll($t.savedItemRemoved, { name: item.name }),
        type: 'success'
      }
    })
  }
  finally {
    pendingItemId.value = ''
  }
}

const saveForLater = async (item) => {
  const { id, name, pdpUrl, productId, productImageURL, qty, recipeId } = item

  try {
    pendingItemId.value = id

    await cartApi.$saveForLater(basketId.value, {
      available,
      cartId: basketId.value,
      defaultProductView: '',
      pdpUrl,
      productId,
      qty,
      recipeId,
      saveForLater: {
        consumerId: auth.consumerId,
        itemId: id,
        saveForLaterId: ''
      },
      type: 'product',
      ...(item.recipeId && { productImageURL })
    })

    await get({ isPatchRequired: true })
    await fetchSavedCartItems()

    toast.add({
      props: {
        title: $t.success,
        message: replaceAll($t.cartItemSavedForLater, { name }),
        type: 'success'
      }
    })
  }
  finally {
    pendingItemId.value = ''
  }
}

const setItemQuantity = async ({ item, qty }: { item: CartItemType, qty: number }) => {
  try {
    pendingItemId.value = item.id
    if (qty === 0)
      await removeItem(item)
    else
      await setItemQty(item, qty)
  }
  finally {
    pendingItemId.value = ''
  }
}

const setSavedItemQuantity = async ({ item, qty }: { item: CartItemType, qty: number }) => {
  try {
    const { itemId, name, pageUrl, productId, productImageURL } = item

    pendingItemId.value = item.id
    await cartApi.$updateSavedForLater({
      cartId: basketId.value,
      productId,
      recipeId: '',
      qty,
      pdpUrl: pageUrl,
      type: 'product',
      available,
      defaultProductView: '',
      productImageURL,
      saveForLater: {
        consumerId: auth.consumerId || undefined,
        itemId,
        saveForLaterId: savedItems.value.saveForLaterId
      }
    })

    fetchSavedCartItems()

    toast.add({
      props: {
        title: $t.success,
        message: replaceAll($t.cartItemQuantityEdited, { name, qty }),
        type: 'success'
      }
    })
  }
  catch (error) {
    assertApiError(error)
    toast.add({
      autoClose: false,
      props: {
        title: 'Error',
        message: error.message,
        type: 'error'
      }
    })
  }
  finally {
    pendingItemId.value = ''
  }
}

const openActionMenu = async (item: CartItemType) => {
  const action = await DialogCartItemActionMenu.open({ hideSaveForLater: item.isDigitalProduct })

  switch (action) {
    case 'edit':
      editItem(item)
      break
    case 'remove':
      removeItem(item)
      break
    case 'save-for-later':
      saveForLater(item)
      break
  }
}

const getDeliveryTimeLabel = (deliveryTime: string) => {
  const date = new Date(deliveryTime)
  return showEstimatedDeliveryOnCart
    ? date.getTime()
      ? replaceAll($t.estimatedDelivery, { expectedTime: useDeliveryTime(date) })
      : `${$t.shipsIn} ${deliveryTime}`
    : $t.shipToHome
}

const getGiftOptionAllowed = (product: CartItemType) => {
  const { isGiftAllowed, shippingNodes, productPromotions } = product
  // Check if product has SFS attribute after shipping presourcing
  const isShippedFromStore = shippingNodes?.some((node) => node.shipNodeType === 'Store')
  const hasAthletePromotion = productPromotions?.some(({ promotionId }) => promotionId === '50%_ATHLETES')

  return isGiftAllowed && !isShippedFromStore && (!user.isAthleteProfile || !hasAthletePromotion)
}

const getPaymentSession = async () => {
  try {
    const session = await cartApi.$getPaymentSession({
      referer: window.location.origin,
      paymentMethodId: 'CREDIT_CARD',
      paymentMethod: 'paypal'
    }, {
      retryDelay: 3000,
      headers: {
        'content-type': 'application/json'
      }
    })

    if (!session.success)
      throw session

    paymentProvider.value = session.provider
    meta.value = session.meta
    locale.value = session.locale
  }
  catch (err) {
    console.error('Get payment session error', err)
  }
}

const isGiftOptionAvailable = computed(() => cart.items.some(getGiftOptionAllowed))

const loadLoyaltyData = () => {
  if ($feature.showLoyaltyPointsInSidebar && auth.loggedIn && !loyalty?.vouchers) {
    cart.getPaymentMethod()
    loyalty.getVouchers()
  }
}

const showEstimatedDelivery = () => {
  const shippingMethod = shippingMethods.value?.find(isPhysicalDelivery)
  if (!shippingMethod) return
  const applicableShippingMethod = getApplicableShippingMethod(shippingMethod)
  if (applicableShippingMethod) {
    estimatedDelivery.value.hasDeliveryDate = !Number.isNaN(
      new Date(applicableShippingMethod.deliveryTime).getTime()
    )
    estimatedDelivery.value.label = replaceAll(
      getFormattedDeliveryDate($t.shippingDetails, applicableShippingMethod.deliveryTime),
      {
        method: applicableShippingMethod.label
      }
    )
  }
}

const handleCustomerNotifications = async () => {
  let storeName: string | undefined
  customerNotifications.value = JSON.parse(JSON.stringify(cachedCustomerNotifications.value || {}))

  Object.values(customerNotifications.value).find((customerNotifications) =>
    customerNotifications.find(({ details, type }) => {
      if (['ChangedStoreId', 'StsToSthTransition'].includes(type)) {
        storeName = details.currentStore?.name
        return true
      }
      return false
    })
  )
  if (storeName) DialogStoreChanged.open({ storeName })

  resetCachedCustomerNotifications()
}

const overridePrice = async (payload) => {
  overrideComplete.value = false
  try {
    if (payload.priceAdjustmentId) {
      await cartApi.$updatePriceAdjustment(basketId.value, payload.priceAdjustmentId, {
        applied_discount: {
          type: 'fixed_price',
          amount: payload.price
        }
      })
    }
    else {
      await cartApi.$addPriceAdjustment(basketId.value, {
        discount: {
          type: 'fixed_price',
          value: payload.price
        },
        item_id: payload.itemId,
        reasonCode: payload.comment,
        level: 'product'
      })
    }
    await get()
    cart.setNotification('success', $t.priceOverrideSuccessful, 'top')
  }
  catch (err) {
    assertApiError(err)
    cart.setNotification('error', err.message, 'top')
  }
  finally {
    overrideComplete.value = true
  }
}

const resetPrice = async (payload, i) => {
  resetOverrideComplete.value = false
  try {
    if (payload.priceAdjustmentId) {
      await cartApi.$deletePriceAdjustment(basketId.value, payload.priceAdjustmentId, {})
      await get()
      cart.setNotification('success', $t.priceResetSuccessful, 'top')
    }
    cartItemRef.value[i].showPriceOverrideForm = false
  }
  catch (err) {
    assertApiError(err)
    cart.setNotification('error', err.message, 'top')
  }
  finally {
    resetOverrideComplete.value = true
  }
}

useSeoMeta({ title: $t.cart })

onMounted(async () => {
  wait(500).then(() => header.expanded = $viewport.lg)

  if (auth.loggedIn && !user.profile) user.get()

  $gtm.push('page.onLoadPageData', router.currentRoute.value, { pageTitle: await getPageTitle() })
  $gtm.push('user.onLoadUserData', await getUserEventData())
  $gtm.push('page.onRouterChange', router.currentRoute.value)
  $gtm.push('cart.onCartLoad', cart)
  $gtm.push('checkout.onCheckout', cart, 'cart')

  if (showEstimatedDeliveryOnCart) {
    const shippingMethod = shippingMethods.value?.find(isPhysicalDelivery)
    if (user.defaultShippingAddress && shippingMethod && !shippingMethod.address?.addressId) {
      const readyForm = convertToShippingAddress(user.defaultShippingAddress)
      readyForm.shippingId = shippingMethod.shippingId
      await cart.addShippingAddress([readyForm], recaptcha)
      await cart.getApplicableShippingMethods()
    }
    showEstimatedDelivery()
  }

  loadLoyaltyData()
  // ensure deviceFingerPrint script is loaded for payment
  $deviceFingerPrint.load()
  // get payment provider
  getPaymentSession()
  handleCustomerNotifications()
})

if (showEstimatedDeliveryOnCart) {
  watchOnce(() => auth.loggedIn, async () => {
    if (!user.profile) await user.get()
    if (!user.defaultShippingAddress) return
    await cart.getApplicableShippingMethods()
    showEstimatedDelivery()
  })
}

watch(() => totals.value.total, () => {
  $sendExtraMonetateEvents()
  loadLoyaltyData()
})

// Once ADYEN is the ONLY provider this condition could be removed
watch(() => paymentProvider.value, () => {
  if (paymentProvider.value === 'ADYEN')
    isAdyenEnabled.value = true
})

// Ensures the skeleton is shown only on first print and not on cart items update
// (e.g., qty change on save for later items)
if (allowSaveForLater) watchOnce(savedCartPending, () => showSkeleton.value = false)

onBeforeUnmount(() => {
  cart.closeCartMergedNotification()
})
</script>
