<template>
  <div data-test-id="form-price-override">
    <base-form
      ref="formRef"
      class="grid gap-4"
      :form
      @submit="submit"
    >
      <vf-form-field v-slot="{ attrs }" name="price" :rule="validateRequired()">
        <vf-input v-bind="attrs" required>
          <slot>{{ $t.price }}</slot>
          <template #input>
            <div class="flex" :class="{ 'flex-row-reverse': !isCurrencyOnLeft }">
              <span v-if="form.price?.length">
                {{ currencySymbol }}
              </span>
              <base-input
                v-model="form.price"
                class="pl-1"
                type="text"
                @keypress="checkValidity($event)"
              />
            </div>
          </template>
        </vf-input>
      </vf-form-field>
      <vf-form-field v-slot="{ attrs }" name="comment" :rule="validateRequired()">
        <vf-textarea v-model="form.comment" v-bind="attrs" required>
          {{ $t.comment }}
        </vf-textarea>
      </vf-form-field>

      <div class="flex space-x-4">
        <vf-button class="flex-1" :disabled="resetPending" :loading="resetPending" variant="secondary" @click="reset">
          {{ $t.reset }}
        </vf-button>
        <vf-button class="flex-1" :disabled="overridePending" :loading="overridePending" type="submit">
          {{ $t.saveChanges }}
        </vf-button>
      </div>
    </base-form>
  </div>
</template>

<script lang="ts" setup>
import type { CartItem, PriceOverride } from '#types/cart'
import type { BaseForm as BaseFormType } from '#components'

const props = defineProps<{
  item: CartItem
  overrideComplete?: boolean
  resetOverrideComplete?: boolean
}>()

const emit = defineEmits<{
  overridePrice: [payload: PriceOverride]
  resetPrice: [payload: PriceOverride]
}>()

const { $t } = useNuxtApp()
const currencySymbol = getCurrencySymbol(useLocale(), props.item.price.currency)
const isCurrencyOnLeft = useFormattedPrice(0, props.item.price.currency).toString().startsWith(currencySymbol)

interface Form {
  price: string
  comment: string
}

const formRef = ref<InstanceType<typeof BaseFormType>>()
const form = reactive<Form>({
  price: '',
  comment: ''
})

const overridePending = ref(false)
const resetPending = ref(false)

/**
 * Prevents non-currency characters from being inputted.
 */
const checkValidity = (event) => {
  if (!/^\d*(?:\.\d{0,2})?$/.test(form.price + event.key))
    event.preventDefault()
}

const productPromotionId = computed(() =>
  props.item.productPromotions?.find(({ promotionId }) => {
    return (/^dw\.manual\d+$/).test(promotionId)
  })?.priceAdjustmentId)

const resetForm = () => {
  form.price = ''
  form.comment = ''
  formRef.value!.reset()
}

const reset = () => {
  resetPending.value = true
  emit('resetPrice', {
    itemId: props.item.id,
    priceAdjustmentId: productPromotionId.value,
  })
}

const submit = () => {
  overridePending.value = true
  emit('overridePrice', {
    price: Number(form.price),
    comment: form.comment,
    itemId: props.item.id,
    priceAdjustmentId: productPromotionId.value,
  })
}

whenever(() => (props.overrideComplete), () => {
  overridePending.value = false
  resetForm()
})

whenever(() => (props.resetOverrideComplete), () => {
  resetPending.value = false
  resetForm()
})
</script>
