<template>
  <div class="py-4 lg:py-6">
    <div class="flex">
      <div class="grow children:skeleton pr-6 md:mr-12 space-y-2">
        <div class="h-4" />
        <div class="h-4 lg:w-1/3 md:w-7/12" />
        <div class="h-4 w-17 md:hidden" />
      </div>
      <div class="h-10 w-6 skeleton <md:mr-2 lg:hidden" />
    </div>
    <div class="flex gap-6 pt-3 md:mt-5">
      <div class="aspect-square w-22 skeleton lg:w-32" />
      <div class="mt-1 children:skeleton space-y-2">
        <div class="h-4 w-41" />
        <div class="h-4 w-41" />
        <div class="h-4 w-22 md:hidden" />
        <div class="h-8 w-25" style="margin-top: 12px" />
        <div class="h-4 w-25 <lg:hidden" style="margin-top: 20px" />
      </div>
      <div class="ml-a mt-1 h-4 w-12 skeleton lg:mr-8 <md:hidden" />
    </div>
    <div class="mt-5 <lg:hidden">
      <div class="flex gap-2">
        <div class="h-6 w-6 skeleton" />
        <div class="mt-1 children:skeleton space-y-2">
          <div class="h-4 w-41" />
          <div class="h-4 w-41" />
        </div>
      </div>
      <div class="mt-5 flex gap-2">
        <div class="h-6 w-6 skeleton" />
        <div class="mt-1 children:skeleton space-y-1">
          <div class="h-4 w-41" />
          <div class="h-4 w-41" />
        </div>
        <div class="ml-a flex children:skeleton self-end">
          <div class="mr-1 h-4 w-6" />
          <div class="mr-6 h-4 w-22" />
          <div class="mr-1 h-4 w-6" />
          <div class="mr-6 h-4 w-22" />
        </div>
      </div>
    </div>
    <div class="mt-5 children:skeleton lg:hidden">
      <div class="mb-2 h-5 w-25" />
      <div class="mb-3 h-4 w-41" />
      <div class="h-4 w-25" />
    </div>
  </div>
</template>
